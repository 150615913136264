import { toggleModal } from './modals'

var target = '#externalLinkModal'

window.addEventListener('click', function (event) {
  if (event.target && event.target.rel && event.target.href && event.target.href !== '#') {
    const relationships = (event.target.rel || '').split(' ')
    const isExternalLink = relationships.reduce(function (isExternalLink, relationship) {
      return isExternalLink || relationship === 'external'
    }, false)

    if (isExternalLink) {
      event.preventDefault()

      var confirmAnchor = document.querySelector('#externalLinkModalRedirectConfirm')
      confirmAnchor.href = event.target.href
      toggleModal(target)
    }
  }
})
