import Cookies from 'js-cookie'
import { toggleModal, dismissModal } from './modals'
import { isValid } from 'date-fns'

const HCP_ATTESTATION_COOKIE_NAME = 'dreamm-trials-hcp-attestation'

const cookieAttributes = {
  // secure: true,
  sameSite: 'strict',
}

const modalId = '#hcpAttestationModal'

document.addEventListener('readystatechange', onReadyStateChange)

const form = document.querySelector('#hcpAttestationForm')
const submitButton = document.querySelector('button[form="hcpAttestationForm"]')
const formCheckboxes = form.querySelectorAll('input[type=checkbox]')
form.addEventListener('submit', onFormSubmit)
form.addEventListener('change', onChange, false)

/**
 * Wait for document to be ready
 * @param {*} event
 */
function onReadyStateChange (event) {
  if (event.target.readyState === 'complete') {
    confirmHcp()

    document.removeEventListener('readystatechange', onReadyStateChange)
  }
}

/**
 * Check session for cookie, and display modal if none found
 */
function confirmHcp () {
  try {
    const token = Cookies.get(HCP_ATTESTATION_COOKIE_NAME)
    if (isValid(Number(token))) {
      return
    }
  } catch (error) {
    console.error('Could not read session cookie')
  }

  toggleModal(modalId)
}

/**
 * Check form for any unchecked boxes; form is valid if every box is checked
 * @param {*} form
 */
function validateForm (form) {
  const checkedCheckboxes = form.querySelectorAll('input[type=checkbox]:checked')
  return checkedCheckboxes.length === formCheckboxes.length
}

/**
 * Handle form submission
 * @param {*} event
 */
function onFormSubmit (event) {
  event.preventDefault()

  if (validateForm(this)) {
    const token = Date.now()
    Cookies.set(HCP_ATTESTATION_COOKIE_NAME, token, cookieAttributes)
    dismissModal(modalId)
  }
}

/**
 * Handle change events on form and set button disabled status
 */
function onChange () {
  const isDisabled = !validateForm(this)
  submitButton.disabled = isDisabled
}
