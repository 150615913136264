// - - - - - - - - - - - - - - - - - - MOBILE MENU - - - - - - - - - - - - - - - //

var mobilemenu = document.querySelector('.mobilemenu')
var hamburger = document.querySelector('.hamburger')
// On click
hamburger.addEventListener('click', function () {
  // Toggle class "is-active"
  hamburger.classList.toggle('is-active')
  mobilemenu.classList.toggle('is-open')
})

// - - - - - - - - - - - - - - - - - - HIDDEN MENU - - - - - - - - - - - - - - - //

var hiddenmenua = document.querySelector('#hiddenmenua')
var menubuttona = document.querySelector('#menubuttona')
// On mouse enter
menubuttona.addEventListener('mouseenter', function () {
  // Toggle class "is-active"
  menubuttona.classList.toggle('is-active')
  hiddenmenua.classList.toggle('is-open')
})
// On mouse leave
menubuttona.addEventListener('mouseleave', function () {
  // Toggle class "is-active"
  menubuttona.classList.toggle('is-active')
  hiddenmenua.classList.toggle('is-open')
})